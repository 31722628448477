import { useState } from 'react';

const PasswordInputForm = ({
  formName,
  labelText,
  isRequired = false,
  value,
  placeholder,
  type,
  onChangeText = () => true,
  id,
  customClasses = '',
  disabled = false,
  error = '',
  touched = false,
  handleBlur = () => true,
  setError = () => true,
  showStrongPassword = false,
  autoComplete = true,
}) => {
  const [passwordStrong, setPasswordStrong] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const onChangePwd = (text) => {
    let strong = 0;
    if (/[0-9]/.test(text)) {
      strong++;
    }
    if (/[A-Z]/.test(text)) {
      strong++;
    }
    if (/[a-z]/.test(text)) {
      strong++;
    }
    if (/[$-/:-?{-~!"^_`[\]@]/.test(text)) {
      strong++;
    }
    if (text?.length > 8) {
      strong++;
    }
    setPasswordStrong(strong);

    onChangeText(text);
  };

  const getTextStrongPassword = () => {
    switch (passwordStrong) {
      case 0:
      default:
        return 'Security level';
      case 1:
      case 2:
        return 'Low';
      case 3:
      case 4:
        return 'Medium';
      case 5:
        return 'Strong';
    }
  };

  const getBulletColorStrongPassword = () => {
    switch (passwordStrong) {
      case 0:
      default:
        return 'tag-bg-grey_light';
      case 1:
      case 2:
      case 3:
      case 4:
        return 'tag-bg-error';
      case 5:
        return 'tag-bg-success';
    }
  };

  const getTextColorStrongPassword = () => {
    switch (passwordStrong) {
      case 0:
      default:
        return 'tag-c-grey_light';
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return 'tag-c-black';
    }
  };

  const enableChange = (e) => {
    if (autoComplete) {
      return;
    }
    e.preventDefault();
  };

  return (
    <div
      className={`tag-input__container ${customClasses ? customClasses : null}`}
    >
      <label
        htmlFor={formName}
        className={`input__label ${isRequired ? 'input__label--required' : ''}`}
        data-required={isRequired.toString()}
      >
        {labelText}
      </label>
      <input
        type={showPassword ? 'text' : 'password'}
        id={id}
        name={formName}
        placeholder={placeholder}
        className={`input__form ${
          !!error && touched ? 'input__form--error' : null
        }`}
        value={value}
        onChange={(e) => {
          e.preventDefault();
          onChangePwd(e?.target?.value);
        }}
        required={isRequired}
        disabled={disabled}
        onBlur={handleBlur}
        autoComplete={autoComplete ? 'on' : 'off'}
        onCut={enableChange}
        onCopy={enableChange}
        onPaste={enableChange}
      />
      <i
        className={`show-password ${
          showPassword ? 'tag-c-black' : 'tag-c-grey'
        } ${showPassword ? 'icon-visible' : 'icon-hidden'}`}
        style={{ fontSize: '20px' }}
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      />
      <div className={'password--details'}>
        {!!error && touched ? (
          <p className={'tag-label_small tag-c-error tag-mt-tiny'}>{error}</p>
        ) : (
          <p className={'tag-label_small tag-mt-tiny'}>&nbsp;</p>
        )}
        {showStrongPassword && (
          <div className="row  " style={{ justifyContent: 'flex-end' }}>
            <p
              className={`tag-label_small tag-right_text tag-mt-tiny ${getTextColorStrongPassword()} `}
              style={{ paddingRight: '5px' }}
            >
              {getTextStrongPassword()}
            </p>
            <span
              className={`password--details-circle tag-mt-tiny ${getBulletColorStrongPassword()}`}
            ></span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordInputForm;
