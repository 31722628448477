import { useEffect, useState } from 'react';
import countryCodes from './../../utils/CountryCodes.json';

const PhoneInput = ({
  formName,
  labelText,
  isRequired = false,
  value,
  placeholder,
  type,
  onChangeText = () => true,
  onChangePrefix = () => true,
  id,
  customClasses = '',
  disabled = false,
  errorPhoneNumber = '',
  errorPrefixNumber = '',
  touched = false,
  handleBlur = () => true,
  prefix = '',
  closeAllDropdowns = () => true,
  forceCloseDropdown = false,
  autoComplete = true,
  disabledPrefix = false,
}) => {
  const [open, setOpen] = useState(false);
  const [prefixList, setPrefixList] = useState(countryCodes);
  // const [localPrefix, setLocalPrefix] = useState(prefix);

  const searchPrefix = (value) => {
    if (!value || value === '+') {
      return setPrefixList(countryCodes);
    }
    if (!isNaN(value) && typeof parseInt(value) === 'number') {
      const newList = countryCodes?.filter((prefix) =>
        prefix?.dial_code?.includes(value)
      );
      setPrefixList(newList);
    } else {
      const newList = countryCodes?.filter((prefix) =>
        prefix?.name?.toLowerCase()?.includes(value.toLowerCase())
      );
      setPrefixList(newList);
    }
  };

  useEffect(() => {
    if (!forceCloseDropdown) {
      return;
    }
    open && setOpen(!open);
  }, [forceCloseDropdown, setOpen, open]);

  // useEffect(() => {
  //   onChangePrefix(localPrefix);
  // }, [localPrefix]);

  return (
    <div
      className={`tag-input__container ${customClasses ? customClasses : null}`}
    >
      <label
        htmlFor={formName}
        className={`input__label ${isRequired ? 'input__label--required' : ''}`}
        data-required={isRequired.toString()}
      >
        {labelText}
      </label>
      <div className="row">
        <div
          className={`select__form select__form--prefix ${open ? 'select__form__open' : ''
            } ${(!!errorPrefixNumber || !!errorPhoneNumber) && touched
              ? 'input__form--error'
              : null
            }`}
          data-open={open.toString()}
        >
          <div>
            <input
              type={'text'}
              id={'prefix'}
              name={'prefix'}
              disabled={disabledPrefix}
              className={`input__form  prefix ${(errorPrefixNumber || errorPhoneNumber) && touched
                  ? 'input__form--error'
                  : null
                }`}
              onClick={() => {
                closeAllDropdowns();
                setTimeout(() => {
                  setOpen(!open);
                }, 10);
              }}
              onChange={(e) => {
                e?.preventDefault();
                const value = e?.target?.value.replace(/\s/g, '');
                onChangePrefix(value);
                searchPrefix(value);
                !open && setOpen(true);
              }}
              value={prefix}
              autoComplete={'off'}
            />
            {!disabledPrefix ? <i
              className={`tag-c-grey ${open ? 'icon-up' : 'icon-down'}`}
              style={{ fontSize: '20px' }}
              onClick={() => {
                closeAllDropdowns();
                setTimeout(() => {
                  setOpen(!open);
                }, 10);
              }}
            /> : null}
          </div>

          {open && (
            <div className={`option__list`} data-open={open.toString()}>
              {prefixList?.map((elem) => {
                return (
                  <div
                    className="option__select"
                    onClick={() => {
                      onChangePrefix(elem?.dial_code);
                      setOpen(false);
                    }}
                    key={elem?.dial_code + elem?.name}
                  >
                    {`${elem?.dial_code} ${elem?.name} `}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <input
          type={type}
          id={id}
          name={formName}
          placeholder={placeholder}
          className={`phone ${(!!errorPrefixNumber || !!errorPhoneNumber) && touched
              ? 'input__form--error'
              : null
            }`}
          value={value}
          onChange={(e) => {
            const value = e?.target?.value.replace(/\s/g, '');
            onChangeText(value);
          }}
          required={isRequired}
          disabled={disabled}
          onBlur={handleBlur}
          data-disabled={disabled}
        />
      </div>
      {(!!errorPrefixNumber || !!errorPhoneNumber) && touched ? (
        <p className={'tag-label_small tag-c-error tag-mt-tiny'}>
          {errorPrefixNumber || errorPhoneNumber}
        </p>
      ) : (
        <p className={'tag-label_small tag-mt-tiny'}>&nbsp;</p>
      )}
    </div>
  );
};

export default PhoneInput;
