import './Footer.scss';

const Footer = () => {
  return <div className={'footer'}>
    <div className={'details'}>
      <img src="images/logo_white.png" style={{ height: '52px' }} alt="" />
      <div className={'tag-info'}>
        <p className={'tag-label_bold'}>Talent Garden</p>
        <p className={'tag-label_regular'}>
          {'Piazza Città di Lombardia n. 1 20124\nMilan, Italy'}
        </p>
      </div>
      <div className={'tag-info'}>
        <p className={'tag-label_bold'}>Hyper Island</p>
        <p className={'tag-label_regular'}>
          {'Virkesvägen 2\n120 30 Stockholm, Sweden'}
        </p>
      </div>
    </div>
    <div className={'disclaimer'}>
      <span>
        {`Copyright © ${new Date().getFullYear()}. All rights reserved.`}
      </span>

    </div>

  </div>
};

export default Footer;
