import { useEffect } from 'react';

const ErrorToast = ({
  closeToast = () => true,
  showToast = false,
  customClasses = '',
  customMessage = '',
  noTimeExpired = false,
  errorDescription = '',
}) => {
  useEffect(() => {
    if (showToast && !noTimeExpired) {
      setTimeout(() => {
        closeToast();
      }, 10000);
    }
  }, [showToast, closeToast, noTimeExpired]);

  return (
    <div
      style={{
        borderRadius: '4px',
        padding: '10px 14px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      className={`tag-bg-error_opacity20 tag-mb-large ${customClasses}`}
    >
      <div>
        <p className={'tag-label_bold'}>
          {customMessage
            ? customMessage
            : 'Ups, something went wrong. Please retry.'}
        </p>
        <p className={'tag-label_regular'}>
          {errorDescription
            ? errorDescription
            : 'If the problem persists please contact your manager.'}
        </p>
      </div>
      <div onClick={closeToast} style={{ cursor: 'pointer' }}>
        <i className={'icon-close tag-c-error'} style={{ fontSize: '20px' }} />
      </div>
    </div>
  );
};

export default ErrorToast;
