import './SupportButton.scss';

const SupportButton = () => {
  return (
    <a
      href="https://helpme.talentgarden.com/"
      target="_blank"
      rel="noreferrer"
      className={'support-button row tag-bg-white'}
    >
      <i className={'icon-profile tag-c-black'} style={{ fontSize: '18px' }} />
      <span className="tag-label_bold tag-c-black"></span>
      {/* <p className="tag-label_bold tag-c-orange">Need help?</p> */}
    </a>
  );
};

export default SupportButton;
