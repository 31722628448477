import axios from "axios";
const httpClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    return handleError(
      error?.message,
      error?.response?.data,
      error?.response?.status
    );
  }
);

export const handleError = (message, data, status) => {
  // if (status === 400 && data?.code === "00U01") {
  //   return Promise.resolve({ message, data, status });
  // }
  return Promise.resolve({ message, data, status });
};

export const postFormData = async (
  queryString,
  payload,
  successCallback = () => true,
  errorCallback = () => true
) => {
  const headers = {
    "Content-Type": "application/json",
  };

  const url = `/v1/users/onboarding${queryString}`;
  console.log("[ API PARAMS ]", JSON.stringify(payload), queryString, url);

  const response = await httpClient.post(url, JSON.stringify(payload), {
    headers: headers,
  });

  if (response?.status === 200 || response?.status === 201) {
    // success
    successCallback(response?.data);
  } else {
    // error
    errorCallback(response?.data);
  }
};
