import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ErrorPage, Homepage } from './pages';
import CryptoJS from 'crypto-js';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={({ location }) => {
            const objParameters = {};

            const params = new URLSearchParams(window.location.search);
            const verification_token = params.get('verification_token') || '';

            Array.from(params).forEach((param) => {
              objParameters[param[0]] = param[1];
            });

            const secretString = Object.keys(objParameters)
              .filter((param) =>
                [
                  'company_name',
                  'manager_name',
                  'hubspot_company_id',
                  'hs_object_id',
                  'hs_object_type',
                  'hubspot_deal_id',
                  'role_id',
                  'invitation_id',
                  'organization_id',
                  'email',
                ].includes(param)
              )
              .sort()
              .map((param) => objParameters[param])
              .join('')
              .concat('enjoy-talent-garden');

            const secret = CryptoJS.MD5(secretString).toString();

            return verification_token === secret ? (
              <Homepage />
            ) : (
              <Redirect
                to={{
                  pathname: '/error',
                  state: { from: location },
                }}
              />
            );
          }}
        ></Route>
        <Route path="/error">
          <ErrorPage title="Error page" />
        </Route>
        <Route path="*">
          <ErrorPage title="Not Found" />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
